
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { ProjectArchiveTier } from "@evercam/shared/types/project"

export default {
  name: "TheArchiveProjectSnackbar",
  data() {
    return {
      projectArchiveSnackbar: true,
    }
  },
  computed: {
    ...mapStores(useProjectStore),
    snackbarPosition() {
      return this.$device.isMobile ? "bottom" : "top"
    },
    isArchiveProjectWarning() {
      return (
        this.projectStore.selectedProject.archiveTier ===
        ProjectArchiveTier.ScheduledForDeletion
      )
    },
  },
}
