
import Vue from "vue"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { camelizeKeys } from "humps"
import { InfoPage } from "@evercam/shared/types"

export default Vue.extend({
  name: "TheCameraTabs",
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useCameraStore),
    routeCameraExid() {
      return camelizeKeys(this.$route.params)?.cameraExid
    },
    bimCompareUrl() {
      return this.$permissions.user.can.access.bim() &&
        this.$permissions.camera.has.bim() &&
        !this.$permissions.project.has.go()
        ? `${this.cameraStore.cameraRoute}/bim-compare`
        : `${this.cameraStore.cameraRoute}/info/${InfoPage.BimCompare}`
    },
    compareUrl() {
      return this.$permissions.project.has.go()
        ? `${this.cameraStore.cameraRoute}/info/${InfoPage.Compare}`
        : `${this.cameraStore.cameraRoute}/compare`
    },
  },
  watch: {
    routeCameraExid(value) {
      if (value && this.$refs.tabs) {
        this.$refs.tabs?.onResize()
      }
    },
  },
  methods: {
    onTabClicked(e: Event & { path?: HTMLAnchorElement[] }): undefined | void {
      const href = e.path?.find((el) => el.tagName === "A")?.href
      const isSameRoute = href && href === document.location.href
      if (isSameRoute) {
        return
      }
    },
  },
})
