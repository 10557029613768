
import UserBasicInfosDialog from "@/components/users/UserBasicInfosDialog"
import WeatherDialog from "@evercam/dashboard/components/weather/WeatherDialog"
import EvercamGoDialog from "@/components/go/EvercamGoDialog.vue"
import { useRecordingsStore } from "@/stores/recordings"
import { useWeatherStore } from "@/stores/weather"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
export default {
  name: "GlobalDialogs",
  components: {
    WeatherDialog,
    UserBasicInfosDialog,
    EvercamGoDialog,
  },
  data() {
    return {
      userBasicInfosDialog: false,
    }
  },
  computed: {
    ...mapStores(
      useRecordingsStore,
      useWeatherStore,
      useProjectStore,
      useCameraStore,
      useAccountStore
    ),
    areRequiredFieldsPopulated() {
      return this.accountStore.persona && this.accountStore.telephone
    },
  },
  watch: {
    areRequiredFieldsPopulated: {
      handler(value) {
        if (value) {
          this.userBasicInfosDialog = false
        }
        if (
          !value &&
          this.accountStore.token &&
          this.accountStore.evercamAccount === false
        ) {
          this.userBasicInfosDialog = true
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    if (this.accountStore.evercamAccount) {
      this.userBasicInfosDialog = false
    }
  },
}
